import React from 'react';
import { Navigate } from 'react-router-dom';
import { getItem } from '../localStorage';

function ProtectedRoute({ children }) {

    if (getItem("admin_token")) {

    } else {
        return <Navigate to="/" replace />
    }

    return children;
}

export default ProtectedRoute;