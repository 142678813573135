import React, { useState, useEffect, useRef } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../components/loader";
import swal from "sweetalert";
import moment from "moment";
import { baseUrl, dateFormate, formatBalance, formatWalletAddress } from "../config/config";
// import "bootstrap/dist/css/bootstrap.min.css";
import { apiService } from "../service/api.service";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const RedeemList = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = (msg) => {
        setMessage(msg);
        setShow(true);
    };

    const tooltip = <Tooltip id="tooltip">View</Tooltip>;
    // const { filterType, setFilterType } = useContext(MyContext)
    const [searchTerm, setSearchTerm] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [userData, setUserData] = useState([]);
    const [copied, setcopied] = useState(false);
    const [copiedHash, setcopiedHash] = useState(false);
    const [totalItems, setTotalItems] = useState();
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);

    const [type, setType] = useState("");

    const [limit, setlimit] = useState(10);

    // const [userId, setUserId] = useState("")

    useEffect(() => {
        RedeemList(page, searchTerm, statusFilter);
    }, [page, searchTerm, statusFilter]);

    const copytooltip = (
        <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
    );



    const copyHashtooltip = (
        <Tooltip id="tooltip">{copiedHash ? "Copied" : "Copy"}</Tooltip>
    );

    async function RedeemList(page, searchTerm = "", statusFilter = "ALL") {
        setLoader(true);
        try {
            const response = await apiService.redeemListData(page, searchTerm, statusFilter);
            if (response?.status == 200) {
                console.log("response.data.data", response.data);
                let responseData = response.data
                setUserData(responseData);
                setTotalItems(response?.data?.pagination);

                setLoader(false);
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);

                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);

                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then((value) => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    }

    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber);
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            RedeemList(pageNumber - 1);
        }
    }


    const setcopytext = () => {
        setTimeout(() => {
            setcopied(false);
        }, "1000");
    };

    const setcopytextHash = () => {
        setTimeout(() => {
            setcopiedHash(false);
        }, "1000");
    };

    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}

                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-center m-0">Redeem List</h2>
                                    </div>
                                    <div className="heading-top-area-right">
                                        <div className="search-area redem-area-search">
                                            <div className="redem-area-input">
                                                <input
                                                    type="text"
                                                    placeholder="Search by Wallet Address"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            RedeemList(page, e.target.value, statusFilter);
                                                        }
                                                    }}
                                                />
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </div>

                                            <div className="redem-area-select">
                                                <select
                                                    value={statusFilter}
                                                    onChange={(e) => {
                                                        setStatusFilter(e.target.value);
                                                        RedeemList(page, searchTerm, e.target.value);
                                                    }}
                                                >
                                                    <option value="">All Statuses</option>
                                                    <option value="FAILED">Failed</option>
                                                    <option value="COMPLETE">Complete</option>
                                                    <option value="PENDING">Pending</option>
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>WalletAddress</th>
                                                <th>Type</th>
                                                <th className="email-section">Folio Points</th>
                                                <th>Point Redeem</th>
                                                <th>Status</th>
                                                <th>TransactionHash</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData?.data && userData?.data?.length > 0 ? (
                                                userData?.data?.map((data, index) => {
                                                    // console.log("data", data);
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td
                                                                onClick={setcopytext}
                                                                style={{ cursor: "pointer" }}
                                                            >{data?.user_wallet_address ? formatWalletAddress(data?.user_wallet_address) : "-"}
                                                                {data?.user_wallet_address && <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={copytooltip}
                                                                >
                                                                    <CopyToClipboard
                                                                        text={data?.user_wallet_address}
                                                                        onCopy={() => setcopied(true)}
                                                                    >
                                                                        <i
                                                                            class="fa fa-clipboard"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </CopyToClipboard>
                                                                </OverlayTrigger>}
                                                            </td>
                                                            <td>{data.type}</td>
                                                            <td>{data?.folio_points}</td>
                                                            <td>{data?.points_redeem}</td>
                                                            <td>
                                                                {data?.status}
                                                            </td>
                                                            <td
                                                                onClick={setcopytextHash}
                                                                style={{ cursor: "pointer" }}
                                                            >{data?.hash ? formatWalletAddress(data?.hash) : "-"}
                                                                {data?.hash && <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={copyHashtooltip}
                                                                >
                                                                    <CopyToClipboard
                                                                        text={data?.hash}
                                                                        onCopy={() => setcopiedHash(true)}
                                                                    >
                                                                        <i
                                                                            class="fa fa-clipboard"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </CopyToClipboard>
                                                                </OverlayTrigger>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>

                                    {userData?.data && userData.data?.length > 0 ? (
                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={totalItems?.itemsPerPage}
                                            totalItemsCount={totalItems?.totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {/* modal */}
            <Modal
                show={show}
                onHide={handleClose}
                centered
                className="message-content-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
