import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";

export function PageNotFound() {
  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="no-found">
                <img src={require("../assets/images/no-found.svg").default} />
                <h2>Page not found</h2>
                <p className="text-center">
                  We're sorry, the page you requested could not be found. Please
                  go back to the home page.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
