import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import "react-toastify/dist/ReactToastify.css";
import { apiService } from "../../service/api.service";
import {
  DeclareContractAddress,
  MyContext,
  NftMintContractAddress,
  PredictionLaunchContractAddress,
  feedTokenWallet,
  folioTokenAddress,
  formatBalance,
  formatNumberNotation,
  fundTransferContract,
  fundTransferDiamondContract,
  fundTransferFolioContract,
  fundWalletAddress,
  platformEarningsAddress,
  polygonRpcUrl,
  tokenAddress,
} from "../../config/config";
import { useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Web3 from "web3";
import ERC20_ABI from '../../common/ABIs/ERC20Abi.json'
import { components } from "react-select";
export const Home = () => {
  const { filterType, setFilterType, openLoad, setOpenLoad } =
    useContext(MyContext);
  const [loader, setLoader] = useState(false);
  const [dashbordData, setDashbordData] = useState([]);
  const [fundWalletBal, setfundWalletBal] = useState();
  const [copied, setcopied] = useState(false);
  const setcopytext = () => {
    setTimeout(() => {
      setcopied(false);
    }, "1000");
  };
  useEffect(() => {
    dashboardList();
    getFundBalance();
    getAllowances();
  }, []);
  const copytooltip = (
    <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
  );
  async function dashboardList() {
    setLoader(true);
    try {
      const response = await apiService.Dashboard();
      if (response?.status == 200) {
        console.log("?????????????dashbord", response?.data?.data);
        setDashbordData(response?.data);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  async function getFundBalance() {
    setLoader(true);
    try {
      const response = await apiService.getFundBalance();
      if (response?.status == 200) {
        setfundWalletBal(formatBalance(response?.data?.data));
        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  const [allowances, setAllowances] = useState({
    fundTransferAllowance: '0',
    fundTransferDiamondAllowance: '0',
    fundTransferFolioAllowance: '0',
    folioBalance: '0',
    usdcBalance: '0',
    nftMintBalance: '0',
    predictionLaunchBalance: '0',
    declareResultsBalance: '0',
  });


  const getAllowances = async () => {
    const web3 = new Web3(polygonRpcUrl);
    let predictionLaunchBalance=0, declareResultsBalance=0, nftMintBalance=0, fundTransferAllowance = 0, fundTransferDiamondAllowance = 0, fundTransferFolioAllowance = 0, folioBalance = 0, usdcBalance = 0;
    try {
      nftMintBalance = await web3.eth.getBalance(NftMintContractAddress);
      nftMintBalance = Number(nftMintBalance) / 10 ** 18;
      nftMintBalance = `${formatNumberNotation(nftMintBalance)} POL`;
      console.log("nftMintBalance", nftMintBalance);

      // Get Prediction Launch Wallet Balance
    predictionLaunchBalance = await web3.eth.getBalance(PredictionLaunchContractAddress);
    predictionLaunchBalance = Number(predictionLaunchBalance) / 10 ** 18;
    predictionLaunchBalance = `${formatNumberNotation(predictionLaunchBalance)} POL`;
    console.log("predictionLaunchBalance", predictionLaunchBalance);

    // Get Declare Results Wallet Balance
    declareResultsBalance = await web3.eth.getBalance(DeclareContractAddress);
    declareResultsBalance = Number(declareResultsBalance) / 10 ** 18;
    declareResultsBalance = `${formatNumberNotation(declareResultsBalance)} POL`;

      const usdcTokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
      const decimals = await usdcTokenContract.methods.decimals().call();
      const symbol = await usdcTokenContract.methods.symbol().call();

      fundTransferAllowance = await usdcTokenContract.methods.allowance(feedTokenWallet, fundTransferContract).call();
      fundTransferAllowance = Number(fundTransferAllowance) / 10 ** Number(decimals);
      fundTransferAllowance = `${formatNumberNotation(fundTransferAllowance)} ${symbol}`;
      fundTransferDiamondAllowance = await usdcTokenContract.methods.allowance(feedTokenWallet, fundTransferDiamondContract).call();
      fundTransferDiamondAllowance = Number(fundTransferDiamondAllowance) / 10 ** Number(decimals);
      fundTransferDiamondAllowance = `${formatNumberNotation(fundTransferDiamondAllowance)} ${symbol}`;

      usdcBalance = await usdcTokenContract.methods.balanceOf(feedTokenWallet).call();
      usdcBalance = Number(usdcBalance) / 10 ** Number(decimals);
      usdcBalance = `${formatNumberNotation(usdcBalance, false)} ${symbol}`;

    } catch (error) {

    }


    try {
      const folioTokenContract = new web3.eth.Contract(ERC20_ABI, folioTokenAddress);
      const decimals = await folioTokenContract.methods.decimals().call();
      const symbol = await folioTokenContract.methods.symbol().call();
      fundTransferFolioAllowance = await folioTokenContract.methods.allowance(feedTokenWallet, fundTransferFolioContract).call();
      fundTransferFolioAllowance = Number(fundTransferFolioAllowance) / 10 ** Number(decimals);
      console.log("fundTransferFolioAllowance", fundTransferFolioAllowance);

      fundTransferFolioAllowance = `${formatNumberNotation(fundTransferFolioAllowance)} ${symbol}`;
      folioBalance = await folioTokenContract.methods.balanceOf(feedTokenWallet).call();
      folioBalance = Number(folioBalance) / 10 ** Number(decimals);
        // folioBalance = `${formatNumberNotation(folioBalance)}`;
      folioBalance = `${formatNumberNotation(folioBalance, false)} ${symbol}`;

    } catch (error) {
      console.error('Error fetching allowance:', error);
    }

    setAllowances({
      fundTransferAllowance,
      fundTransferDiamondAllowance,
      fundTransferFolioAllowance,
      folioBalance,
      usdcBalance,
      nftMintBalance,
      predictionLaunchBalance,
      declareResultsBalance,
    })

  };


  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area main-home-top-area">
          <Row className="justify-content-center">
            <Col md={12} lg={11} className="p-0">
              <Row className="justify-content-center m-2">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Wallet</h3>
                      <p>
                        {fundWalletAddress.substring(0, 4)}...
                        {fundWalletAddress.substring(
                          fundWalletAddress.length - 4
                        )}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={fundWalletAddress}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {fundWalletBal ? (
                              <>
                                {fundWalletBal}{" "}
                                <img
                                  className="usd-coin"
                                  src={require("../../assets/images/usd-coin.png")}
                                  alt="img"
                                />
                              </>
                            ) : (
                              <>-</>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/total-user.svg").default
                        }
                      />
                    </div>
                    <Link
                      to="/users"
                      className="dashboardlink"
                      onClick={() => setFilterType("trucker")}
                    >
                      <div className="card-content">
                        <h3>Total Users</h3>
                        <h2>
                          {" "}
                          {loader ? (
                            <InlineLoader />
                          ) : (
                            <>
                              {dashbordData && typeof dashbordData?.userCount !== 'undefined' ? (
                                <>{dashbordData?.userCount}</>
                              ) : (
                                <> <InlineLoader /></>
                              )}
                            </>
                          )}
                        </h2>
                      </div>
                    </Link>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/prediction-count.svg")
                            .default
                        }
                      />
                    </div>
                    <Link
                      to="/predictions"
                      className="dashboardlink"
                      onClick={() => setOpenLoad(true)}
                    >
                      <div className="card-content">
                        <h3>Total Predictions </h3>
                        <h2>
                          {" "}
                          {loader ? (
                            <InlineLoader />
                          ) : (
                            <>
                              {dashbordData && typeof dashbordData?.predictionCount !== 'undefined' ? (
                                <>{dashbordData?.predictionCount}</>
                              ) : (
                                <><InlineLoader /></>
                              )}
                            </>
                          )}
                        </h2>
                      </div>
                    </Link>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/comission.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Total commission</h3>
                      <h2>
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {dashbordData && typeof dashbordData?.totalCommission !== 'undefined'  ? (
                              formatBalance(dashbordData.totalCommission)
                            ) : (
                              <><InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/reward-amount.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Total reward amount</h3>
                      <h2>
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {dashbordData && typeof dashbordData?.totalRewardAmount !== 'undefined' ? (
                              <>
                                {formatBalance(dashbordData?.totalRewardAmount)}
                              </>
                            ) : (
                              <> <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/amount.svg").default}
                      />
                    </div>
                    <div className="card-content">
                      <h3>Total amount</h3>
                      <h2>
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {dashbordData && typeof dashbordData?.totalAmount !== 'undefined'  ? (
                              <>{formatBalance(dashbordData?.totalAmount)}</>
                            ) : (
                              <>  <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/reward-amount.svg")
                            .default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Total Platform Earnings</h3>
                      <p>
                        {platformEarningsAddress.substring(0, 4)}...
                        {platformEarningsAddress.substring(
                          platformEarningsAddress.length - 4
                        )}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={platformEarningsAddress}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2>
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {dashbordData && typeof dashbordData?.totalPlatformEarnings !== 'undefined' ? (
                              <>
                                {formatNumberNotation(Number(dashbordData?.totalPlatformEarnings) /10**18)} POL
                              </>
                            ) : (
                              <> <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>


                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Transfer Allowance (Pearls, Sapphires, Group)</h3>
                      <p>
                        {feedTokenWallet.substring(0, 4)}...{feedTokenWallet.substring(feedTokenWallet.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={feedTokenWallet}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.fundTransferAllowance && allowances.fundTransferAllowance > "0" ? (
                              <>
                                {allowances.fundTransferAllowance}{" "}

                              </>
                            ) : (
                              <> <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                {console.log("::::::::::",allowances.fundTransferAllowance)}

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Transfer Allowance (Emeralds, Rubies, Diamonds)</h3>
                      <p>
                        {feedTokenWallet.substring(0, 4)}...{feedTokenWallet.substring(feedTokenWallet.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={feedTokenWallet}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.fundTransferDiamondAllowance && allowances.fundTransferDiamondAllowance >"0" ? (
                              <>
                                {allowances.fundTransferDiamondAllowance}{" "}

                              </>
                            ) : (
                              <><InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Transfer Folio Allowance</h3>
                      <p>
                        {feedTokenWallet.substring(0, 4)}...{feedTokenWallet.substring(feedTokenWallet.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={feedTokenWallet}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.fundTransferFolioAllowance && allowances.fundTransferFolioAllowance >"0" ? (
                              <>
                                {allowances.fundTransferFolioAllowance}{" "}

                              </>
                            ) : (
                              <><InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Transfer Folio Balance</h3>
                      <p>
                        {feedTokenWallet.substring(0, 4)}...{feedTokenWallet.substring(feedTokenWallet.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={feedTokenWallet}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.folioBalance && allowances.folioBalance >"0" ? (
                              <>
                                {allowances.folioBalance}{" "}

                              </>
                            ) : (
                              <> <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Fund Transfer USDC Balance</h3>
                      <p>
                        {feedTokenWallet.substring(0, 4)}...{feedTokenWallet.substring(feedTokenWallet.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={feedTokenWallet}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.usdcBalance && allowances.usdcBalance >"0" ? (
                              <>
                                {allowances.usdcBalance}{" "}

                              </>
                            ) : (
                              <>  <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>NFT Mint</h3>
                      <p>
                        {NftMintContractAddress.substring(0, 4)}...{NftMintContractAddress.substring(NftMintContractAddress.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={NftMintContractAddress}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.nftMintBalance && allowances.nftMintBalance > "0" ? (
                              <>
                                {allowances.nftMintBalance}{" "}

                              </>
                            ) : (
                              <>  <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

                {/* {console.log("PredictionLaunchContractAddress::::::", allowances.predictionLaunchBalance)} */}

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Prediction launch wallet</h3>
                      <p>
                        {PredictionLaunchContractAddress.substring(0, 4)}...{PredictionLaunchContractAddress.substring(PredictionLaunchContractAddress.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={PredictionLaunchContractAddress}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.predictionLaunchBalance && allowances.predictionLaunchBalance > "0" ? (
                              <>
                                {allowances.predictionLaunchBalance}{" "}

                              </>
                            ) : (
                              <>  <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>

{/* {console.log("DeclareContractAddress::::::",typeof allowances.declareResultsBalance, allowances.declareResultsBalance)} */}
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={
                          require("../../assets/images/box-wallet.svg").default
                        }
                      />
                    </div>
                    <div className="card-content">
                      <h3>Declare results</h3>
                      <p>
                        {DeclareContractAddress.substring(0, 4)}...{DeclareContractAddress.substring(DeclareContractAddress.length - 4)}
                        <OverlayTrigger placement="top" overlay={copytooltip}>
                          <CopyToClipboard
                            text={DeclareContractAddress}
                            onCopy={() => setcopied(true)}
                          >
                            <i
                              class="fa fa-clipboard"
                              aria-hidden="true"
                              onClick={setcopytext}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </p>
                      <h2 className="mt-0">
                        {" "}
                        {loader ? (
                          <InlineLoader />
                        ) : (
                          <>
                            {allowances.declareResultsBalance && allowances.declareResultsBalance >"0" ? (
                              <>
                                {allowances.declareResultsBalance}{" "}

                              </>
                            ) : (
                              <>  <InlineLoader /></>
                            )}
                          </>
                        )}
                      </h2>
                    </div>
                  </div>
                </Col>


              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
