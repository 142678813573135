export const validateService = {
  predictionValidate,
};

function predictionValidate(
  predictionFields,
  setpredictionFieldsErr,
  predictionFieldsErr, answerOptionValidation
) {

  console.log("predictionFields", predictionFields);
  let formValidated = true;
  console.log("predictionFields?.prediction_type", predictionFields?.prediction_type);
  if (predictionFields.question.trim() === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "Please enter question",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  }
  else if (predictionFields.description.trim() === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        description: "Please enter description",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  } else if (answerOptionValidation()) {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        description: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
      };
    });
  }
  else if (predictionFields.duration.trim() === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        description: "",
        duration: "Please select duration",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  } else if (predictionFields.startDateTime.trim() == "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "Please select betting start time",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  }
  else if (predictionFields.threshold_time === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "Please select threshold time",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  }

  else if (predictionFields?.prediction_type == "RISK-FREE" && (predictionFields?.token_address?.trim() === "" || !predictionFields?.token_address)) {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "Please enter Contract Address",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  }
  else if (predictionFields?.prediction_type == "RISK-FREE" && predictionFields?.chain_name?.trim() === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "Please enter chain name",
        symbol: "",
        // chain_type:""
      };
    });
  }
  else if (predictionFields?.prediction_type == "RISK-FREE" && predictionFields?.symbol?.trim() == "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "",
        token_address: "",
        chain_name: "",
        symbol: "Please enter symbol",
        // chain_type:""
      };
    });
  }
  else if (predictionFields?.prediction_type == "RISK-FREE" && predictionFields.amount === "") {
    formValidated = false;
    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
        amount: "Please enter Amount",
        token_address: "",
        chain_name: "",
        symbol: "",
        // chain_type:""
      };
    });
  }
  // else if (predictionFields?.prediction_type == "RISK-FREE" && predictionFields.chain_type === "") {
  //   formValidated = false;
  //   setpredictionFieldsErr((obj) => {
  //     return {
  //       ...obj,
  //       question: "",
  //       start_time: "",
  //       end_time: "",
  //       outcome: "",
  //       threshold_time: "",
  //       amount:"",
  //       token_address:"",
  //       chain_name:"",
  //   symbol:"",
  //   chain_type:"Please Select chain_type"
  //     };
  //   });
  // }
  else {
    let error = { ...predictionFieldsErr };

    predictionFields.commissionData.filter((item, index) => {
      error.commissionData.push({ walletAddress: "", percentage: "" });
      if (
        predictionFields?.commissionData[index]?.walletAddress == "" ||
        !predictionFields?.commissionData[index]?.walletAddress ||
        !predictionFields?.commissionData[index]?.walletAddress.trim()
      ) {
        formValidated = false;
        error.commissionData[index].walletAddress =
          "Please enter wallet address";
      }
      if (
        predictionFields?.commissionData[index]?.percentage == "" ||
        !predictionFields?.commissionData[index]?.percentage ||
        !predictionFields?.commissionData[index]?.percentage
      ) {
        formValidated = false;
        error.commissionData[index].percentage = "Please enter percentage";
      }
    });

    predictionFields.specificWallets?.filter((item, index) => {
      error.specificWallets.push("");
      if (
        predictionFields.canBet &&
        (predictionFields?.specificWallets[index] == "" ||
          !predictionFields?.specificWallets[index] ||
          !predictionFields?.specificWallets[index].trim())
      ) {
        formValidated = false;
        error.specificWallets[index] = "Please enter wallet address";
      }
    });
    if (predictionFields.commissionPercentage != 100) {
      formValidated = false;
      error.commissionPercentage = "Sum commission should be 100.";
    }

    setpredictionFieldsErr((obj) => {
      return {
        ...obj,
        ...error,
        question: "",
        start_time: "",
        end_time: "",
        outcome: "",
        threshold_time: "",
      };
    });
  }
  return formValidated;
}
