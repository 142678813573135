import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { ThreeDotSpinner } from "../loader";
// import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
// import copy from "copy-to-clipboard";
import { apiService } from "../../service/api.service";
// import userImg from "../../assets/images/byDefaultUser.png"
// import { MyContext,apiUrl } from "../../config/config";
// import { useContext } from "react";
import moment from "moment";
import { baseUrl, dateFormate, formatBalance } from "../../config/config";
import "bootstrap/dist/css/bootstrap.min.css";

export const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (msg) => {
    setMessage(msg);
    setShow(true);
  };

  const tooltip = <Tooltip id="tooltip">View</Tooltip>;
  // const { filterType, setFilterType } = useContext(MyContext)
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  const [type, setType] = useState("");

  const [limit, setlimit] = useState(10);

  // const [userId, setUserId] = useState("")

  useEffect(() => {
    contactUs(page);
  }, []);

  async function contactUs(page) {
    setLoader(true);
    try {
      const response = await apiService.contactUs(page);
      if (response?.status == 200) {
        let responseData = response.data.data.contactList;
        setUserData(responseData);
        setTotalItems(response?.data?.data.totalRecords);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber);
    if (page != pageNumber - 1) {
      setPage(pageNumber - 1);
      contactUs(pageNumber - 1);
    }
  }
  const formatAddress = (addr) => {
    return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
  };

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Contact Us</h2>
                  </div>
                  <div className="heading-top-area-right">
                    <div className="search-area">
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>

                        <th>Name</th>
                        <th>Email</th>
                        <th className="email-section">Phone Number</th>
                        <th>Message</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.email}</td>
                              <td>{data?.phone_number}</td>
                              <td className="contact-msg">
                                {data.message && data.message.length > 50
                                  ? data.message.slice(0, 50) + "..."
                                  : data.message}
                                {data.message && data.message.length > 50 ? (
                                  <a
                                    href="#"
                                    onClick={() => handleShow(data.message)}
                                  >
                                    View More
                                  </a>
                                ) : null}
                              </td>
                              <td>
                                {moment(data?.createdAt).format(dateFormate)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  {userData && userData.length > 0 ? (
                    <Pagination
                      activePage={page + 1}
                      itemsCountPerPage={limit}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="message-content-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
