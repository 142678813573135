import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer-content">
        <p style={{ color: "white" }}>
          © Copyright {new Date().getFullYear()}{" "}
        </p>
      </div>
    </>
  );
};
export default Footer;
