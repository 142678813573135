import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../service/api.service";
import { baseUrl, formatBalance, formatWithDecimals } from "../../config/config";
import { ThreeDotSpinner } from "../loader";
import moment from "moment";

export const SoloDetail = () => {


    const props = useParams();
    const [loader, setLoader] = useState(false);
    const history = useNavigate();
    const [detailData, setdetailData] = useState();


    useEffect(() => {
        getDetail();
    }, []);

    const getDetail = async () => {
        setLoader(true);
        const response = await apiService.getSoloDetail(props.id);
        console.log("response.data.data", response.data)
        if (response.status == 200) {
            setdetailData(response.data);
            setLoader(false);
        }
    };
    // const tooltip = (
    //     <Tooltip id="tooltip">
    //       <strong>Holy guacamole!</strong> Check this info.
    //     </Tooltip>
    //   );

    return (
        <>
            <div className="dashboard-main-area">
                {loader ? <ThreeDotSpinner /> : ""}
                <section className="common-padding prediction-detail-area">
                    <Container fluid className="spacing-area-section">
                        <Row className="justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="top-back-btn" onClick={() => history(-1)}>
                                    <button type="button" variant="unset">
                                        <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
                                    </button>
                                </div>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">Solo Detail</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center m-2">
                            <Col md={12} lg={11}>
                                <Row className="bg-main-area">
                                    <Col md={12} lg={5} xl={6}>
                                        <div className="prediction-detail-left">
                                            <div className="prediction-detail-image">
                                                {detailData?.image &&
                                                    detailData?.image != "undefined" ? (
                                                    <img src={detailData?.image} />
                                                ) : (
                                                    <img
                                                        src={require("../../assets/images/dummy.png")}
                                                        alt="img"
                                                    />
                                                )}
                                            </div>
                                            <div className="prediction-detail-left-content">
                                                <h5>{detailData?.title}</h5>
                                                <p>{detailData?.description}</p>
                                            </div>
                                        </div>
                                    </Col>


                                    <Col md={12} lg={7} xl={6}>
                                        <div className="prediction-box-outer">
                                            <div className="prediction-detail-right">
                                                {detailData?.result &&
                                                    <p className="progress-total mt-4">
                                                        Result: {" "}
                                                        <span>{detailData?.result?.toString()}</span>
                                                    </p>
                                                }
                                                {detailData?.amount &&
                                                    <p className="progress-total ">
                                                        Pool Amount: <span>{formatWithDecimals(detailData?.amount, detailData?.decimal)} USDC</span>
                                                    </p>
                                                }

                                                <p className="progress-total ">
                                                    Threshold Time: <span>{detailData?.threshold_time} Minutes </span>
                                                </p>
                                                <p className="progress-total capitalized">
                                                    Prediction Type: <span>{detailData?.type}  </span>
                                                </p>

                                                {detailData?.launchTime?.length > 0 ? <p className="progress-total capitalized">
                                                    Launch Time: <span>{detailData?.launchTime?.join(',')}  </span>
                                                </p> : ""}

                                                <p className="progress-total mb-0">
                                                    Options  :
                                                </p>
                                                <ul className="progess-total-right-area">
                                                    {detailData && detailData?.options?.map((option, index) => {
                                                        return (
                                                            <>
                                                                <li>{option}</li>
                                                            </>
                                                        )
                                                    })}
                                                </ul>

                                                <div className="betting-window">
                                                    <h6>Prediction Timeframe :</h6>
                                                    <div className="betting-window-content">
                                                        <div className="start-end-date">
                                                            <div className="start-date">
                                                                <p>
                                                                    Start  Time<span>(utc)</span>{" "}
                                                                </p>
                                                            </div>
                                                            <div className="end-date">
                                                                <p>
                                                                    End Time<span>(utc)</span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div className="start-end-date">
                                                            <div className="start-date-info">
                                                                <p>
                                                                    12:00 Am
                                                                </p>
                                                            </div>
                                                            <div className="end-date-info">
                                                                <p>
                                                                    11:59 PM
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
};
