import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Collapse } from "react-bootstrap";
import { MyContext } from "../config/config";
const Sidebar = ({ isOpen, setIsOpen }) => {
  const { affiliateRequest, setAffiliateRequest, openLoad, setOpenLoad } =
    useContext(MyContext);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/users") {
    }
    if (location.pathname.split("/")[1] == "PrimaryClient") {
      localStorage.setItem("tab", "primary");
    } else if (location.pathname.split("/")[1] == "UserList") {
      localStorage.setItem("tab", "users");
    } else if (
      location.pathname.split("/")[1] !== "userInfo" &&
      location.pathname.split("/")[1] !== "PrimaryClient" &&
      location.pathname.split("/")[1] !== "UserList"
    ) {
      localStorage.removeItem("tab");
    }
    if (location.pathname.split("/")[1] == "LoadManagement") {
      setOpenLoad(true);
    } else {
      setOpenLoad(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="sidebar-overlay">
        <div
          onClick={() => setSidebarOpenMobile(!sidebarOpenMobile)}
          className={`${sidebarOpenMobile ? "sidebar-cross-btn" : "sidebar-toggle-btn"
            } `}
        >
          <img
            src={
              sidebarOpenMobile
                ? require("../assets/images/sidebar-cross.svg").default
                : require("../assets/images/sidebar-menu.svg").default
            }
            alt="img"
          />
        </div>

        <section className="sidebar">
          <div className="logo">
            <img src={require("../assets/images/logo.png")} alt="" />
          </div>
          <div className="">
            <Link
              to="/Home"
              className={
                location.pathname == "/Home"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Dashboard
            </Link>

            <Link
              to="/users"
              onClick={() => localStorage.setItem("tab", "users")}
              className={
                (localStorage.getItem("tab") == "users" &&
                  location.pathname == "/users") ||
                  (localStorage.getItem("tab") == "users" &&
                    location.pathname.split("/")[1] == "userInfo") ||
                  location.pathname == "/users"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img src={require("../assets/images/audit.svg").default} alt="" />
              User Management
            </Link>
            <Link
              to="/predictions"
              className={
                location.pathname == "/predictions" ||
                  location.pathname.split("/")[1] == "prediction-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Prediction Management
            </Link>

            <Link
              to="/maximum-gas-fee"
              className={
                location.pathname == "/maximum-gas-fee" 
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Set Maximum Gas Fee
            </Link>

            <Link
              to="/bets-won"
              className={
                location.pathname == "/bets-won" ||
                  location.pathname.split("/")[1] == "/bets-won"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Bets Won
            </Link>

            <Link
              to="/whitelist"
              className={
                location.pathname == "/whitelist" ||
                  location.pathname.split("/")[1] == "/whitelist"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Whitelist
            </Link>

            <Link
              to="/solo-predictions"
              className={
                location.pathname == "/solo-predictions" ||
                  location.pathname.split("/")[1] == "solo-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Solo Templates
            </Link>

            <Link
              to="/group-templates"
              className={
                location.pathname == "/group-templates" ||
                  location.pathname.split("/")[1] == "group-template-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Group Templates
            </Link>
            <Link
              to="/multichoice-prediction-templates"
              className={
                location.pathname == "/multichoice-prediction-templates" ||
                  location.pathname.split("/")[1] == "multichoice-prediction-template-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Multi Choice Prediction Templates
            </Link>

            <Link
              to="/freegroup-prediction-templates"
              className={
                location.pathname == "/freegroup-prediction-templates" ||
                  location.pathname.split("/")[1] == "freegroup-prediction-template-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
             Free Group Templates
            </Link>

            <Link
              to="/failed-transactions"
              className={
                location.pathname == "/failed-transactions" 
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
            Failed Transactions
            </Link>

            {/* <Link
              to="/result_failed-transactions"
              className={
                location.pathname == "/result_failed-transactions" 
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
            Result Failed Transactions
            </Link> */}



            <Link
              to="/transaction"
              className={
                location.pathname == "/transaction"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/transaction-new.svg").default}
                alt=""
              />
              Transaction Management
            </Link>
            <Link
              to="/contact-us"
              className={
                location.pathname == "/contact-us" ||
                  location.pathname.split("/")[1] == "contact-us"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/contact-us.svg").default}
                alt=""
              />
              Contact Us
            </Link>
          </div>
        </section>

        {sidebarOpenMobile && (
          <section className="sidebar sidebar-mobile">
            {/* <div className="logo">
              <img src={require("../assets/images/front-logo.png")} alt="" />
            </div> */}
            <div className="">
              <Link
                to="/Home"
                className={
                  location.pathname == "/Home"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                Dashboard
              </Link>

              <Link
                to="/users"
                onClick={() => localStorage.setItem("tab", "users")}
                className={
                  (localStorage.getItem("tab") == "users" &&
                    location.pathname == "/users") ||
                    (localStorage.getItem("tab") == "users" &&
                      location.pathname.split("/")[1] == "userInfo") ||
                    location.pathname == "/users"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img src={require("../assets/images/audit.svg").default} alt="" />
                User Management
              </Link>
              <Link
                to="/predictions"
                className={
                  location.pathname == "/predictions" ||
                    location.pathname.split("/")[1] == "prediction-detail"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                Prediction Management
              </Link>

              <Link
                to="/maximum-gas-fee"
                className={
                  location.pathname == "/maximum-gas-fee"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                Set Maximum Gas Fee
              </Link>
              <Link
                to="/solo-predictions"
                className={
                  location.pathname == "/solo-predictions" ||
                    location.pathname.split("/")[1] == "solo-detail"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                Solo Predictions
              </Link>

              <Link
                to="/transaction"
                className={
                  location.pathname == "/transaction"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/transaction-new.svg").default}
                  alt=""
                />
                Transaction Management
              </Link>
              <Link
                to="/failed-transactions"
                className={
                  location.pathname == "/failed-transactions"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/transaction-new.svg").default}
                  alt=""
                />
                Failed Transaction
              </Link>
              <Link
                to="/contact-us"
                className={
                  location.pathname == "/contact-us" ||
                    location.pathname.split("/")[1] == "contact-us"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/contact-us.svg").default}
                  alt=""
                />
                Contact Us
              </Link>
            </div>
          </section>
        )}
      </div>
    </>
  );
};
export default Sidebar;
