import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    dateFormate,
    formatWithDecimals,
    numberToLocaleString,
    transactionurl,
} from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import Select from "react-select";
import { useLocation } from "react-router-dom";


export const SetMaximumGasFee = () => {

    const [loader, setLoader] = useState(false);
    const [gasFeeList, setGasFeeList] = useState([]);
    const [page, setPage] = useState(0);
    const [error, setError] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editedGasFee, setEditedGasFee] = useState("");



    useEffect(() => {
        gasFeeSettings();
    }, []);


    async function gasFeeSettings() {
        setLoader(true);
        try {

            const response = await apiService.gasFeeSettings();
            if (response?.status == 200) {
                setGasFeeList(response.data.gasFeesList);
                setLoader(false);
            }

        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };

    const tooltip = (
        <Tooltip id="tooltip">
            <p className="m-0 p-0">Edit</p>
        </Tooltip>
    );


    const handleEditClick = (rowData) => {
        setSelectedRow(rowData);
        setEditedGasFee(rowData.gasFee ? Number(rowData.gasFee) / 10 ** 18 : ""); // Initialize with existing gas fee
        setError("");
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        if (!editedGasFee) {
            setError("Gas fee is required.");
            return;
        }

        if (isNaN(editedGasFee) || Number(editedGasFee) <= 0) {
            setError("Gas fee must be greater than zero.");
            return;
        }
        setError("");
        setLoader(true);
        const response = await apiService.updateGasFeeSettings(selectedRow._id, { gasFee: (Number(editedGasFee) * 10 ** 18) });
        await gasFeeSettings();
        setShowEditModal(false);
        swal({
            text: `Gas fee updated successfully.`,
            icon: "success",
        });
    };

    return (
        <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12} lg={11}>
                            <div className="heading-top-area">
                                <div className="d-flex ">
                                    <h2 className="align-self-center m-0">Set Maximum Gas Fee</h2>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={11}>
                            <div className="audit-request-box">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Fee Type</th>
                                            <th>Gas Fee</th>
                                            <th>Updated At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            gasFeeList &&
                                                gasFeeList?.length > 0 ? (
                                                gasFeeList.map((data, index) => {

                                                    const serialNumber = (page) * 10 + index + 1;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{serialNumber}</td>
                                                            <td> {data?.feeType ? data?.feeType : "N/A"}</td>
                                                            <td> {data?.gasFee ? (Number(data?.gasFee) / 10 ** 18) : "N/A"}</td>
                                                            <td> {data?.updatedAt ? moment(data?.updatedAt).format(dateFormate) : "N/A"}</td>
                                                            <td>
                                                                <div className="view-eye-area">
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => handleEditClick(data)}

                                                                    >
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={tooltip}
                                                                        >
                                                                            <div className="edit-pencil">
                                                                                <i className="fa fa-pencil" style={{ cursor: "pointer" }}></i>
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            )
                                                : (
                                                    <tr className="no-dat-found-box">
                                                        <td colSpan={5}>
                                                            <img
                                                                src={
                                                                    require("../../assets/images/no-data.svg")
                                                                        .default
                                                                }
                                                                alt="da"
                                                            />
                                                            <h3 className="text-center text-light">
                                                                No data found
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>

                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>






            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Gas Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="editGasFee">
                        <Form.Label>Gas Fee</Form.Label>
                        <Form.Control maxLength={8}
                            type="text"
                            value={editedGasFee}
                            onChange={(e) => setEditedGasFee(e.target.value)}
                            placeholder="Enter new gas fee"
                        />
                        {error && <span className="text-danger">{error}</span>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};
