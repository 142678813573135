import {
    Col,
    Container,
    Dropdown,
    Form,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    dateFormate,
    formatWithDecimals,
} from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";


export const Whitelist = () => {


    const [page, setPage] = useState(1);
    const [items, setItems] = useState(0);
    const [loader, setLoader] = useState(false);







    useEffect(() => {
        getWhitelistContracts(page);
    }, []);


    async function getWhitelistContracts(page) {
        setLoader(true);
        try {
            let params = {
                page: page - 1
            }
            const response = await apiService.getWhitelistContracts(params);
            if (response?.status == 200) {
                setItems(response.data.data)
                console.log("response.data", response.data.data);
                setLoader(false);
            }

        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handlePageChange(pageNumber) {
        setPage(pageNumber);
        getWhitelistContracts(pageNumber);
    };




    return (
        <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12} lg={11}>
                            <div className="heading-top-area">
                                <div className="d-flex ">
                                    <h2 className="align-self-center m-0">Whitelist</h2>
                                </div>
                                <div className="heading-top-area-right">
                                    {/* <div className="filter-top-area me-2">
                                        <Form.Group
                                            className=""
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label className="mb-0">Filter:</Form.Label>
                                            <Select
                                                options={filterOptions}
                                                placeholder="All"
                                                onChange={(e) => (handleFilterChange(e.value), setFilterValue(e.value))}
                                            />
                                        </Form.Group>
                                    </div> */}

                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={11}>
                            <div className="audit-request-box">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Contract Address</th>
                                            <th>Whitelisted Address</th>
                                            <th>Created At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items != null && items?.totalContracts && items?.totalContracts > 0 ? (
                                            items?.contracts?.map((data, index) => {

                                                const serialNumber = (page - 1) * 10 + index + 1;

                                                return (
                                                    <tr key={index}>
                                                        <td>{serialNumber}</td>
                                                        <td>{data.contract_address ? data.contract_address : "N/A"}</td>
                                                        <td>{data.whitelisted_address ? data.whitelisted_address : "N/A"}</td>
                                                        <td>
                                                            {moment(data?.createdAt).format(dateFormate)}
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                        )
                                            : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>

                                {items && items?.totalContracts && items?.totalContracts > 10 ? (
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={items?.totalContracts}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};
