import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";
import { apiService } from "../../service/api.service";
import { InlineLoader } from "../../components/loader";
import { setItem, getItem } from "../../util/localStorage";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useContext } from "react";
import { MyContext } from "../../config/config";
import { ThreeDotSpinner } from "../../components/loader";
export const Login = () => {
  const md5 = require("md5");
  const { affiliateRequest, setAffiliateRequest } = useContext(MyContext);
  const [loginObj, setLoginObj] = useState({
    email: "",
    password: "",
    role: "Admin",
  });
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("password");

  const requestFormSchema = object({
    email: string().email().required("Email is required").trim(),
    password: string().required("Password is required").trim(),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (getItem("admin_token")) {
      navigate("/Home");
    }
    setAffiliateRequest(false);
  }, []);

  async function handleFormSubmit(value) {
    setLoader(true);
    try {
      const data = {
        email: value.email,
        password: value.password,
      };

      let response = await apiService.login(data);
      console.log("login????//......", response?.data);
      if (response?.status == 200 && response?.data) {
        let responseData = response?.data.data;
        console.log(response?.data.data);
        setItem("admin_token", responseData);
        setLoader(false);
        localStorage.setItem("login", true);
        localStorage.setItem("Id", responseData?.id);

        swal("Success", "You have successfully logged in.", "success").then(
          (value) => {
            if (value) {
              window.location.href = "/Home";
            }
          }
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("??????????????????", error);
      if (error?.response?.status == 400) {
        swal({
          icon: "error",
          text: error?.response?.data?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      } else {
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }

  function FormikErrorMessage(props) {
    return (
      <label id="uname-error" className="text-danger" for={props.for}>
        {props.message[0].toUpperCase() + props.message.slice(1).toLowerCase()}
      </label>
    );
  }

  return (
    <>
      <div className="login-area">
        {loader ? <ThreeDotSpinner className="loginPageLoader" /> : ""}
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={6}>
              <div className="logo-top">
                <img src={require("../../assets/images/logo.png")} alt="logo" />
              </div>
              <div className="login-content-area">
                <h2>Login</h2>
                <p>Please enter your email address and password.</p>
                <Formik
                  initialValues={loginObj}
                  validationSchema={requestFormSchema}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    setLoader(true);
                    handleFormSubmit(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <FormikErrorMessage
                            for="email"
                            message={errors.email}
                          />
                        ) : null}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <div className="password-area">
                          <Form.Control
                            type={type}
                            placeholder="Enter Password"
                            id="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          {type == "password" ? (
                            <i
                              class="fa fa-eye"
                              style={{ cursor: "pointer" }}
                              aria-hidden="true"
                              onClick={() => setType("text")}
                            ></i>
                          ) : (
                            <i
                              class="fa fa-eye-slash"
                              style={{ cursor: "pointer" }}
                              aria-hidden="true"
                              onClick={() => setType("password")}
                            ></i>
                          )}
                        </div>

                        {errors.password && touched.password ? (
                          <FormikErrorMessage
                            for="password"
                            message={errors.password}
                          />
                        ) : null}
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="login-btn-submit"
                      >
                        {loader ? <InlineLoader /> : "Login"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
