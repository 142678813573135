import { Row, Col, Button, Container, Form } from "react-bootstrap"
import { useEffect, useState } from "react"
import Bydefault from "../../assets/images/byDefaultUser.png"
import { apiService } from "../../service/api.service"
import { Unauthorized, apiBaseUrl, apiUrl } from "../../config/config"
import swal from "sweetalert"
import { MyContext } from "../../config/config"
import { useContext } from "react"
import { ThreeDotSpinner } from "../loader"
export function Profile(){
    const {editProfile, setEditProfile}=useContext(MyContext)
    const [loader, setLoader]=useState(false)
    const [adminImage, setAdminImage]=useState("")
    const [adminFirstName, setAdminFirstName]=useState("")
    const [adminLastName, setAdminLastName]=useState("")
    const [adminEmail, setAdminEmail]=useState("")
    const [firstNameError, setFirstNameError]=useState("")
    const [lastNameError, setLastNameError]=useState("")
    const [showImage, setShowImage]=useState("")
    const id=localStorage.getItem("Id")
    useEffect(()=>{
      const id=localStorage.getItem("Id")
      profile(id)
    },[])

    async function profile(){
        setLoader(true)
        try{
           const response=await apiService.GetProfile(id)
           if(response.status == 200){
              setLoader(false)
              console.log("response......getprofile", response)
              setAdminImage(response?.data?.data?.image)
              setAdminFirstName(response?.data?.data?.first_name)
              setAdminLastName(response?.data?.data?.last_name)
              setAdminEmail(response?.data?.data?.email)
              setShowImage("")
           }
        }catch(error){
           setLoader(false)
           if(error?.response?.status == 401){
            Unauthorized()
           }
        }
    }

    async function updateProfile(){
        setLoader(true)
        try{
            const formData= new FormData()
            formData.append('first_name', adminFirstName)
            formData.append('last_name', adminLastName)
            {showImage && formData.append('image', showImage ? adminImage:"")}
           const response=await apiService.ProfileUpdate(formData, id)
           if(response?.status == 200){
            setLoader(false)
            console.log("updaterespons.....",response)
            swal({icon:"success", text:"Profile Updated Successfully"}).then((value)=>{
              if(value){
                profile(id)
              }
            })

           }
        }catch(error){
            setLoader(false)
           if(error?.response?.status == 401){
            Unauthorized()
           }
        }
    }

    const onChangeImage=(e)=>{
       const data=e.target.files[0]
       console.log("onchangeimage...",data)
       setAdminImage(data)
       setShowImage(URL.createObjectURL(data))
    }
   
    

    const submit=(e)=>{
        e.preventDefault()
        if(adminFirstName == ""){
            setFirstNameError("Please enter first name")
        }else{
            setFirstNameError("")
        }
        if(adminLastName == ""){
            setLastNameError("Please enter last name")
        }else{
            setLastNameError("")
        }

        if(adminFirstName !== "" && adminLastName !== ""){
            updateProfile()
            console.log(">>>>>true>>")
        }else{
            console.log(">>>>>false>>")
        }
    }
    return(
        <div className="dashboard-main-area">
           {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
            <div className="dashboard-area-heading management-heading">
                <Row className="row justify-content-center align-items-center mb-3">
                    <Col md={11}>
                        <Row>
                            <Col md={4} className="d-flex p-0">
                                <h2 className="align-self-center m-0">{editProfile ? <>Edit Profile</>:<>Profile</>}</h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="pb-4 justify-content-center">
                    <Col md={11} className="commission">
                        {editProfile == false ? 
                            <Row className="p-5">
                                <Col md={4} className="d-flex justify-content-center">
                                  {adminImage ? <img src={adminImage} className="rounded-circle" alt="image"/>:<img src={Bydefault} className="rounded-circle" alt="image" height={200} width={200}/> }
                                </Col>
                                <Col md={8}>
                                   <Row className="h-100 d-flex align-items-center">
                                      <Col>
                                         <span className="text-secondary">NAME</span>
                                         <h6 className="mt-1">{adminFirstName ? <>{adminFirstName +" "+ adminLastName}</>:<>-</>}</h6>
                                      </Col>
                                      <Col>
                                         <span className="text-secondary">EMAIL ADDRESS</span>
                                         <h6 className="mt-1">{adminEmail ? <>{adminEmail}</>:<>-</>}</h6>
                                      </Col>
                                   </Row>
                                </Col>
                            </Row>
                            :
                            <Row className="p-4">
                                <Col>
                                   <Form>
                                     <Row>
                                        <Col md={4}>
                                            <div style={{width:"200px", height:"200px"}} className="m-auto">
                                              {adminImage ? <img src={showImage ?  showImage:adminImage} className="rounded-circle" alt="iamge" height={200} width={200}/>:<img src={Bydefault} alt="image" className="rounded-circle"  height={200} width={200}/>}
                                            </div>
                                            <input className="profileImage-select" type="file" onChange={(e)=>onChangeImage(e)}/>
                                            <span className="d-block m-auto pt-2" style={{width:"200px", textAlign:"center"}}>Upload image</span>
                                        </Col>
                                        <Col className="d-flex align-items-center">
                                           <Row>
                                             <Col>
                                               <Form.Group>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control text="text" className="editprofile" placeholder="Please enter first name" value={adminFirstName} onChange={(e)=>setAdminFirstName(e.target.value)} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }}/>
                                               </Form.Group>
                                               <span className="text-danger">{firstNameError}</span>
                                             </Col>
                                             <Col>
                                                <Form.Group>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control text="text" className="editprofile" placeholder="Please enter last name" value={adminLastName} onChange={(e)=>setAdminLastName(e.target.value)} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }}/>
                                                </Form.Group>
                                                <span className="text-danger">{lastNameError}</span>
                                             </Col>
                                             <Col md={12} className="mt-3 profilr-update">
                                                <Button onClick={(e)=>submit(e)}>
                                                    Update
                                                </Button>
                                             </Col>
                                           </Row>
                                        
                                        </Col>
                                     </Row>
                                   </Form>   
                                </Col>
                            </Row>
                         }
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
    )
}
export default Profile;