import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import swal from "sweetalert";
import { apiService } from "../service/api.service";
import { formatWithDecimals } from '../config/config';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = () => {
    const [fiveDayStatus, setFiveDayStatus] = useState([]);
    const [loader, setLoader] = useState(false);

    const ResultFiveDayStatus = async () => {
        try {
            const response = await apiService.resultFiveDayStatus();
            if (response.status === 200) {
                console.log("ResultFiveDayStatus .data", response.data?.data);
                setFiveDayStatus(response?.data?.data);
            }
        } catch (error) {
            setLoader(false);
            const message = error?.response?.data?.message || error?.message;
            if (error?.response?.status === 401) {
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
                swal({ icon: "error", text: message, button: "OK" });
            }
        }
    };

    useEffect(() => {
        ResultFiveDayStatus();
    }, []);

    // Group data by date
    const groupedData = fiveDayStatus.reduce((acc, item) => {
        const date = item._id.date;
        if (!acc[date]) {
            acc[date] = { folio: null, usdc: null };
        }
        if (item._id.symbol === 'folio') {
            acc[date].folio = formatWithDecimals(item.totalAmount, 18);
        } else if (item._id.symbol === 'usdc') {
            acc[date].usdc = formatWithDecimals(item.totalAmount, 6);
        }
        return acc;
    }, {});

    const labels = Object.keys(groupedData).sort();
    const folioData = labels.map(date => groupedData[date].folio);
    const usdcData = labels.map(date => groupedData[date].usdc);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Folio',
                data: folioData,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
            {
                label: 'USDC',
                data: usdcData,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
            }
        ]
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Total Amount'
                }
            }
        }
    };
    return <Line data={chartData} options={options} />;
};

export default LineChart;
