import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import { InlineLoader } from "../../components/loader";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
export const ChangePassword = () => {
  const md5 = require("md5");
  const [loader, setLoader] = useState(false);
  const [apierror, setApierror] = useState("");
  const navigate = useNavigate();
  const [oldpassword, setOldpassword] = useState("");
  const [oldpassworderror, setOldpassworderror] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [newpassworderror, setNewpassworderror] = useState("");
  const [cpassworderror, setcpassworderror] = useState("");
  const PASSWORDREGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  /*-----------onChange old password----------*/
  function onChangeOldPassword(e) {
    console.log("old password.......>>>>>", e);
    const data = e;
    setOldpassword(data);
    if (!data) {
      setOldpassworderror("Please enter the password");
    } else {
      setOldpassworderror("");
    }
  }

  /*-----------onChange new password----------*/

  function onChangeNewPassword(e) {
    console.log("new password........>>>>>", e);
    const data = e;
    setNewpassword(e);
    if (!data) {
      setNewpassworderror("Please enter the new password");
    } else if (!PASSWORDREGEX.test(data)) {
      setNewpassworderror(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
    } else {
      setNewpassworderror("");
    }
  }

  const handleSubmit = (e) => {
    const data = {
      oldPassword: oldpassword,
      newPassword: newpassword,
    };
    e.preventDefault();
    let formValid = true;
    if (!oldpassword) {
      formValid = false;
      setOldpassworderror("Please enter the old password");
    } else if (!newpassword) {
      setOldpassworderror("");
      formValid = false;
      setNewpassworderror("Please enter the new password");
    } else if (!PASSWORDREGEX.test(newpassword)) {
      setOldpassworderror("");
      formValid = false;
      setNewpassworderror(
        "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
      );
    } else if (!cpassword) {
      setOldpassworderror("");
      setNewpassworderror("");
      formValid = false;
      setcpassworderror("Please enter the confirm password");
    } else if (newpassword != cpassword) {
      setOldpassworderror("");
      setNewpassworderror("");
      formValid = false;
      setcpassworderror("New password and confirm should be same.");
    } else {
      formValid = true;
      setOldpassworderror("");
      setcpassworderror("");
      setNewpassworderror("");
    }

    if (formValid) {
      swal({
        title: "Are you sure?",
        text: "Do you want to change the password",
        icon: "warning",
        buttons: ["Cancel", "Yes, Change Password"],
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setLoader(true);
          apiService
            .changePassword(data)
            .then((res) => {
              if (res?.status == 200) {
                setLoader(false);
                console.log("changepassword.......", res);
                swal("success", res?.data?.message, "success").then((value) => {
                  localStorage.clear();
                  setTimeout(() => {
                    navigate("/");
                  }, 1000);
                });
              }
            })
            .catch((error) => {
              setApierror(error?.message);
              console.log("catch errrorrrrrr....", error);
              if (error?.response?.status == 401) {
                setLoader(false);
                swal({
                  icon: "error",
                  text: "Unauthorized",
                  button: "OK",
                }).then(() => {
                  localStorage.clear();
                  window.location.href = "/";
                });
              } else {
                swal({
                  icon: "error",
                  text: error?.response.data.message,
                  button: "OK",
                }).then((value) => {
                  setLoader(false);
                });
              }
            });
        }
      });
    }
  };

  return (
    <>
      <div className="dashboard-main-area">
        <div className="change-section">
          {loader ? <ThreeDotSpinner className="loginPageLoader" /> : ""}
          <Container>
            <Row className="justify-content-center">
              <Col md={10} lg={7} xl={6}>
                <div className="change-password-area">
                  <h2 className="chamge-text">Change Password</h2>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter old password"
                        onChange={(e) => onChangeOldPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <span className="text-danger">{oldpassworderror}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter New Password"
                        onChange={(e) => onChangeNewPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />

                      <span className="text-danger">{newpassworderror}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Confirm Password"
                        onChange={(e) => setcpassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />

                      <span className="text-danger">{cpassworderror}</span>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="login-btn-submit"
                    >
                      {loader ? <InlineLoader /> : "Submit"}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
