import {
    Col, Container, Form, OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { apiService } from "../../service/api.service";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    baseUrl,
    dateFormate,
    formatBalance,
    formatWithDecimals,
    transactionurl,

} from "../../config/config";

const formatDate = (newdate) => {
    const year = newdate.getFullYear();
    const month = String(newdate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(newdate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
const BetsWon = () => {
    const [loader, setLoader] = useState(false)
    const [totalItems, setTotalItems] = useState();
    const [page, setPage] = useState(0);
    const [type, setType] = useState("All");

    const [copied, setcopied] = useState(false);
    const [betsList, setbetsList] = useState([])
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));


    const [usdcAmount, setUsdcAmount] = useState(null);
    const [foiloAmount, setFoiloAmount] = useState(null);
    const usdcAmountRef = useRef(0);
    const foiloAmountRef = useRef(0);
    // State for balances
    const [usdcBalance, setUsdcBalance] = useState(0);
    const [folioBalance, setFolioBalance] = useState(0);

    const options = [
        { value: "All", label: "All" },
        { value: "solo-predictions", label: "Solo Predictions" },
        { value: "group", label: "Group Predictions" },
        { value: "multi-choice-predictions", label: "Multichoice Predictions" },
    ];
    const formatAddress = (addr) => {
        return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
    };
    useEffect(() => {
        onGetBets(0, type, startDate, endDate);
    }, [])
    const copytooltip = (
        <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
    );
    const onGetBets = async (page, types, startDate, endDate) => {
        console.log("===sss==", page, types, startDate, endDate)
        setLoader(true);
        try {
            let params = {
                page: page + 1,
                soloType: types ? types : "All",
                // date: new Date(dates).toISOString()
                startDate,
                endDate

            }
            console.log("params", params);
            const response =
                await apiService.get_winning_predictions(params);
            const responseOnlyAmount = await apiService.get_winning_predictions_only_amount(params);

            console.log("response>>>>>>", response?.data);
            if (responseOnlyAmount.status == 200 || response.status == 200) {
                setTotalItems(response?.data?.pagination?.totalItems);
                // Set USDC and FOILO amounts based on responseOnlyAmount
                usdcAmountRef.current = formatWithDecimals(0, 6);
                foiloAmountRef.current = formatWithDecimals(0, 18);
                responseOnlyAmount?.data?.data?.forEach(item => {
                    if (item._id === "usdc") {
                        usdcAmountRef.current = formatWithDecimals(item.totalAmount, 6);
                    } else if (item._id === "folio") {
                        foiloAmountRef.current = formatWithDecimals(item.totalAmount, 18);
                    }
                });
                setbetsList(response.data.data)

                setLoader(false);
            }
        } catch (error) {
            console.log("error>>>>", error);
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }
    // const onGetBets = async (page, types, dates) => {
    //     setLoader(true);
    //     try {
    //         let params = {
    //             page: page + 1,
    //             soloType: types ? types : "All",
    //             date: new Date(dates).toISOString()
    //         };
    //         console.log("params", params);
    //         // Initiate both API calls concurrently
    //         const [response, responseOnlyAmount] = await Promise.all([
    //             apiService.get_winning_predictions(params),
    //             apiService.get_winning_predictions_only_amount(params)
    //         ]);

    //         console.log("responseOnlyAmount:::::", responseOnlyAmount);

    //         // if (response.status === 200 || responseOnlyAmount.status === 200) {
    //             console.log("response.data.data", response.data.data);
    //             console.log("responseOnlyAmount:::::", responseOnlyAmount.data?.data);
    //             setbetsList(response.data.data);
    //             setTotalItems(response?.data?.pagination?.totalItems);

    //             // Set USDC and FOILO amounts based on responseOnlyAmount
    //             usdcAmountRef.current = formatWithDecimals(0, 6);
    //             foiloAmountRef.current = formatWithDecimals(0, 18);

    //             responseOnlyAmount?.data?.data?.forEach(item => {
    //                 console.log("item::::::::::", item);
    //                 if (item._id === "usdc") {
    //                     usdcAmountRef.current = formatWithDecimals(item.totalAmount, 6);
    //                 } else if (item._id === "folio") {
    //                     foiloAmountRef.current = formatWithDecimals(item.totalAmount, 18);
    //                 }
    //             });
    //         // }

    //         setLoader(false);
    //     } catch (error) {
    //         console.log("error>>>>", error);
    //         setLoader(false);
    //         if (error?.response?.status === 401) {
    //             swal({ text: "Unauthorized", button: "OK" }).then(() => {
    //                 localStorage.clear();
    //                 window.location.href = "/";
    //             });
    //         }
    //     }
    // };


    console.log("usdcAmount:::::", usdcAmount, foiloAmount)
    function handlePageChange(pageNumber) {
        console.log("handlePageChange", pageNumber)
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            onGetBets(pageNumber - 1, type, startDate, endDate)
        }
    }

    const handleStartDateChange = (event) => {
        const dates = event.target.value; // This is a string in 'YYYY-MM-DD' format
        setStartDate(dates); // Store the Date object in the state
        setEndDate(dates); // Store the Date object in the state
        setPage(0)
        onGetBets(0, type, dates, dates); // Pass the formatted date to onGetBets
    };

    const handleEndDateChange = (event) => {
        const dates = event.target.value; // This is a string in 'YYYY-MM-DD' format
        setEndDate(dates); // Store the Date object in the state
        setPage(0);
        onGetBets(0, type, startDate, dates); // Pass the formatted date to onGetBets
    };
    const handleTypeChange = (typeData) => {
        const types = typeData
        setType(types);
        setPage(0)
        onGetBets(0, types, startDate, endDate);
    };

    const fetchBalances = async () => {
        // Replace with actual API calls to get balances
        const usdcResponse = { totalAmount: 12000000 }; // Mock response for usdc
        const folioResponse = { totalAmount: 2.5e+21 }; // Mock response for folio
        setUsdcBalance(usdcResponse.totalAmount.toFixed(18));
        setFolioBalance(folioResponse.totalAmount.toFixed(6));
    };

    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">
                                            Bets Won
                                        </h2>

                                    </div>
                                    <div className="bets-won-right-area">
                                        <div className="balance-buttons">
                                            <button className="usdc-balance">USDC: {usdcAmountRef.current}</button>
                                            <button className="folio-balance">Folio: {foiloAmountRef.current}</button>
                                        </div>

                                        <div className="heading-top-area-right bets-won-filter">
                                            <div className="filter-top-area me-2">
                                                <div className="date-picker">
                                                    <label>Start Date:</label>
                                                    <input type="date" value={startDate} onChange={handleStartDateChange} />
                                                </div>

                                                <div className="date-picker">
                                                    <label>End Date:</label>
                                                    <input type="date" value={endDate}
                                                        min={startDate ? startDate: new Date().toISOString().split('T')[0]}
                                                        onChange={handleEndDateChange} />
                                                </div>
                                                <Form.Group
                                                    className=""
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label className="mb-0">Filter:</Form.Label>
                                                    <Select
                                                        options={options}
                                                        placeholder="All"
                                                        onChange={(e) => handleTypeChange(e.value)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Prediction Name</th>
                                                <th>Wallet Address</th>
                                                <th>Prediction Type</th>
                                                <th>Win Points </th>
                                                <th>Amount </th>
                                                <th>Date (UTC)</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {betsList && betsList?.length > 0 ? (
                                                // solo-predictions', 'multi-choice-predictions
                                                betsList?.map((data, index) => {
                                                    let data_provider = data.predictionDetails.data_provider
                                                    let type = "Group"
                                                    if (data_provider == "solo-predictions") {
                                                        type = "SOLO"
                                                    } else if (data_provider == "multi-choice-predictions") {
                                                        type = "MultiChoice"
                                                    }

                                                    return (

                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <a
                                                                    target="_blank"
                                                                    href={`prediction-detail/${data?.predictionDetails?.contract_address}`}
                                                                >
                                                                    {data?.predictionDetails?.question}
                                                                </a>
                                                            </td>
                                                            <td> {formatAddress(data.user)}
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={copytooltip}
                                                                >
                                                                    <CopyToClipboard
                                                                        text={data.user}
                                                                        onCopy={() => setcopied(true)}
                                                                    >
                                                                        <i
                                                                            class="fa fa-clipboard"
                                                                            style={{ cursor: "pointer" }}
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </CopyToClipboard>
                                                                </OverlayTrigger></td>
                                                            <td>{type} </td>
                                                            <td>{data.points}</td>
                                                            <td> {Number(formatWithDecimals(data?.reward_amount, data?.predictionDetails?.decimal)).toFixed(2)}  {data?.predictionDetails?.symbol}</td>
                                                            <td>{data.createdAt ? data.createdAt : "N/A"}</td>

                                                        </tr>);
                                                })
                                            ) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </Table>

                                    {betsList.length > 0 && totalItems > 10 ? (
                                        <Pagination
                                            activePage={page + 1}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
};
export default BetsWon;